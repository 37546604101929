import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ComponentCode from './ComponentCode'; // Đảm bảo bạn đã nhập ComponentCode đúng cách
import Layout from './Layout'; // Đảm bảo bạn đã nhập Layout đúng cách

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Layout />}/>
        <Route path="/NMJuO7" element={<ComponentCode />} />
        {/* Định nghĩa thêm các sub-route khác ở đây nếu cần */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;