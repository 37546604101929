import React from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';

const Layout = () => {
    return (
      <div className="App">
        <header className="App-header">
          <img src="https://uxwing.com/wp-content/themes/uxwing/download/crime-security-military-law/safe-icon.png" className="App-logo" alt="logo" />
          <h2>SAFETY CODE CHECKING SERVICE</h2>
          <a className="App-link" href="https://safetycode.online/" target="_blank" rel="noopener noreferrer">
            Online Code Service 
          </a> | Contact us to audit the code: admin@safetycode.online
        </header>
        
        <Outlet /> {/* Nơi các component con sẽ được render */}
  
        {/* Award-winning services section */}
        <section className="award-section">
          <h3>Trusted Award-Winning Services</h3>
          <p>See all prestigious awards recognizing Safetycode’s services and overall mission to make web3.0 a safer place for everyone.</p>
          <div className="award-badges">
            <img src="https://www.cyberscope.io/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FglobeSilver.ff2679d2.png&w=256&q=75" alt="Cybersecurity 2023 Silver Winner" />
            <img src="https://www.cyberscope.io/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fforbes.70f8e2d7.png&w=256&q=75" alt="Forbes Technology Council Official Members" />
            <img src="https://www.cyberscope.io/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FglobeBronze.41ddf1e1.png&w=256&q=75" alt="Globee Awards Bronze Winner" />
            <img src="https://www.cyberscope.io/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FcyberSecurity.e3668d85.png&w=256&q=75" alt="Cyber Security Awards" />
          </div>
        </section>
      </div>
    );
  };

export default Layout;
